import { createContext, useContext, ReactElement, useState } from 'react'
import { SnackbarToastProps } from '../components/snackbarToast'
import { SnackbarToastType } from '../utils/session.utils'

type SnackbarContextType = {
  snackbarProps: SnackbarToastProps
  showSnackbar: (
    type: SnackbarToastType,
    message: string,
    inPage?: string
  ) => void
  closeSnackbar: () => void
}

const STSnackbarContext = createContext<SnackbarContextType | undefined>(
  undefined
)

const defaultState = {
  open: false,
  type: SnackbarToastType.ERROR,
}

export default function SnackbarContext({
  children,
}: {
  children: ReactElement
}): ReactElement {
  const [snackbarProps, setSnackbarProps] =
    useState<SnackbarToastProps>(defaultState)

  const closeSnackbar = () => {
    setSnackbarProps(defaultState)
  }

  const showSnackbar = (
    type: SnackbarToastType,
    message: string,
    inPage?: string
  ) => {
    setSnackbarProps({
      ...snackbarProps,
      open: true,
      type: type,
      message: message,
      inPage: inPage ? inPage : snackbarProps.inPage,
    })
  }
  return (
    <STSnackbarContext.Provider
      value={{
        snackbarProps,
        showSnackbar,
        closeSnackbar,
      }}
    >
      {children}
    </STSnackbarContext.Provider>
  )
}

export const useSnackbar = (): SnackbarContextType =>
  useContext(STSnackbarContext)

import { createTheme, responsiveFontSizes } from '@material-ui/core/styles'

const theme = createTheme({
  palette: {
    primary: {
      light: '#56799C',
      main: '#2C3E50',
      dark: '#1D2A36',
      contrastText: '#FFF',
      '200': '#F4F6FB',
      '300': '#E8EBF2',
      '400': '#8D9EAE',
    },
    secondary: {
      light: '#B6E4DA',
      main: '#27B899',
      dark: '#4F5459',
      contrastText: '#000',
    },
    background: {
      default: '#FFF',
      paper: '#FAFAFA',
    },
    warning: {
      main: '#FF9941',
    },
    error: {
      main: '#f44336',
      dark: '#D83535'
    },
    text: {
      primary: '#2C3E50',
    },
  },
  typography: {
    button: {
      textTransform: 'none',
      cursor: 'pointer',
      fontWeight: 700,
    },
    fontFamily: [
      'Poppins',
      'ui-sans-serif',
      'system-ui',
      '-apple-system',
      'BlinkMacSystemFont',
      'Segoe UI',
      'Roboto',
      'Helvetica Neue',
      'Arial',
      'Noto Sans',
      'sans-serif',
      'Apple Color Emoji',
      'Segoe UI Emoji',
      'Segoe UI Symbol',
      'Noto Color Emoji',
    ].join(','),
    h1: {
      fontSize: '3rem',
      fontWeight: 700,
      lineHeight: 2.5,
    },
    h2: {
      letterSpacing: '-.025em',
      fontWeight: 700,
      fontSize: '2.25rem',
      lineHeight: 1.5,
    },
    h3: {
      fontWeight: 700,
      fontSize: '1.75rem',
      lineHeight: 1.2,
    },
    h4: {
      fontWeight: 700,
      fontSize: '1.125rem',
      lineHeight: 0.5,
    },
    subtitle1: {
      fontWeight: 700,
      fontSize: '1rem',
      lineHeight: 1.75,
    },
    body1: {
      fontSize: '1rem',
      lineHeight: 1.75,
    },
    body2: {
      fontSize: '0.75rem',
      lineHeight: 2,
    },
    caption: {
      fontSize: '0.875rem',
      lineHeight: '2rem',
      letterSpacing: '0rem',
    },
  },
})

export default responsiveFontSizes(theme)

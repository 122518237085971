import {
  createContext,
  useContext,
  ReactElement,
  useState,
  useEffect,
  SetStateAction,
  Dispatch,
} from 'react'
import { CognitoUser } from '@aws-amplify/auth'
import { Auth, Hub } from 'aws-amplify'

type UserContextType = {
  user: CognitoUser | null
  setUser: Dispatch<SetStateAction<CognitoUser>>,
  status: ContextStatusType
}

type ContextStatusType = "unset" | "finished"

const UserContext = createContext<UserContextType | undefined>(undefined)

export default function AuthContext({ children, }: {
  children: ReactElement
}): ReactElement {
  const [user, setUser] = useState<CognitoUser | null>(null)
  const [status, setStatus] = useState<ContextStatusType>("unset")

  useEffect(() => {
    checkUser()
  }, [])

  useEffect(() => {
    const listener = (data) => {
      switch (data.payload.event) {
        case 'signIn':
        case 'signUp':
        case 'signOut':
        case 'signIn_failure':
        case 'tokenRefresh':
        case 'tokenRefresh_failure':
        case 'configured':
          checkUser()
          break
      }
    }
    Hub.listen('auth', listener)
  }, [])

  async function checkUser() {
    try {
      const user = await Auth.currentAuthenticatedUser()
      setUser(user)
    } catch (e) {
      console.error(e)
      setUser(null)
    } finally {
      setStatus('finished')
    }
  }

  return (
    <UserContext.Provider value={{ user, setUser, status }}>
      {children}
    </UserContext.Provider>
  )
}

export const useUser = (): UserContextType => useContext(UserContext)

import React, { useEffect } from 'react'
import Head from 'next/head'
import { ThemeProvider } from '@material-ui/core/styles'
import CssBaseline from '@material-ui/core/CssBaseline'
import theme from '../theme'
import { AppProps } from 'next/app'
import Amplify from 'aws-amplify'

import awsconfig from '../_aws-exports'
import AuthContext from '../context/Auth'
import { ApolloProvider } from '@apollo/client'
import client from '../gql/clients/apollo-client'
import STSnackbarContext from '../context/Snackbar'

Amplify.configure({ ...awsconfig, ssr: true })

function MyApp({ Component, pageProps }: AppProps) {
  useEffect(() => {
    // Remove the server-side injected CSS.
    const jssStyles = document.querySelector('#jss-server-side')
    if (jssStyles) {
      jssStyles.parentElement.removeChild(jssStyles)
    }
  }, [])
  return (
    <>
      <Head>
        <title>SlowTalk | Conductor</title>
        <meta
          name="viewport"
          content="minimum-scale=1, initial-scale=1, width=device-width"
        />
        <link rel="icon" href="/favicon.svg" />
      </Head>
      <STSnackbarContext>
        <AuthContext>
          <ApolloProvider client={client}>
            <ThemeProvider theme={theme}>
              <CssBaseline />
              <Component {...pageProps} />
            </ThemeProvider>
          </ApolloProvider>
        </AuthContext>
      </STSnackbarContext>
    </>
  )
}

export default MyApp
